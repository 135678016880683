.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-top: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $veryLightGrey;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: $white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: $cornflowerBlue;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $cornflowerBlue;
  }
  
  input:checked + .slider:before {
   
    transform: translateX(26px);
  }