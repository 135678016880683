.spinner{

    border: 5px solid rgba($color: #fff, $alpha: 0.4);
    /* Light grey */
    border-top: 5px solid $veryLightGrey;
    /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}