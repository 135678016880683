.checkbox{
    display: block;
     &.record-call , &.record-call.disabled{
         label{
            &::before,&::after{
                content: none;
            }
         }
         input[type="checkbox"] {
             &:checked {
                 &+label {
                     &::before{
                        content: none ;
                        display: none
                     }
                     &::after {
                         content: none ;
                         display: none
                     }
                 }
             }
         }
     }

    input[type="checkbox"] {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;

        &:checked {
            &+label {
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 8px;
                    width: 6px;
                    height: 14px;
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                }

                &::before {
                    background-color: $cornflowerBlue;
                    border: 2px solid $cornflowerBlue;
                }
            }

            &:hover {
                &+label {
                    &::before {
                        background-color: $ceruleanBlue;
                        border: 2px solid $ceruleanBlue;
                    }


                }
            }
        }

    }

    label {
        position: relative;
        cursor: pointer;
        font-size: 14px;
        line-height: 24px;
        color: $nobel;
        user-select: none;

        &::before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid $veryLightGrey;
            border-radius: 4px;
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;

        }

        &:hover {

            // color: red;
            &::before {
                border: 2px solid $nobel;
            }

        }

    }

    &.disabled {
        input[type="checkbox"]  {
            pointer-events: none;

            &:checked {
                &+label {
                    &::before {
                        background-color:$nobel;
                        border: 2px solid $nobel;
                    }
                }
            }
        }

        label {
            pointer-events: none;

            &::before {
                border: 2px solid $whisper;
                background-color: $aliceBlue;
            }
        }
    }

}