* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

// /* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

// /* Handle */
::-webkit-scrollbar-thumb {
  background: $veryLightGrey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-family: "Metropolis", Helvetica, sans-serif;
  overflow: hidden;

  .bg-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: relative;
    z-index: 0;
  }
}

main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  



  &.push-enabled {
    top: 40px;
    height: calc(100% - 40px);

    .whitelabel-header,.global-header{
      top: 40px;
    }

    .push-notification{
      display: flex;
    }


  }



  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 0;
  }

  &.grayscale-filter {
    &::after {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
}

.hide {
  display: none !important;
}

.v-hide {
  visibility: hidden;
}

.o-hide {
  opacity: 0;
  pointer-events: none;
}

.no-rotate {
  transform: none !important;
}

video.lq-video {
  object-fit: contain !important;
}

main.animate-elements {
  .user-actions {
    // opacity: 50%;
    // transition: opacity 0.2s ease-in-out;
  }

  .btn-chat-holder {
    transform: translate(70px, -70px);
    
    @include mq(phone,max){
      transform: translate(70px, -70px) scale(0.5);
    }
  }

  .btn-options-holder {
    transform: translate(70px, 70px);
    @include mq(phone,max){ 
      transform: translate(70px, 70px) scale(0.5);
    }

    ul {
      // visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in;
    }

    // opacity: 50%;
  }

  .btn-sharescreen-holder {
    transform: translate(-70px, 70px);
    // opacity: 50%;
    @include mq(phone,max){
      transform: translate(-70px, 70px) scale(0.5);
    }
  }

  .btn-invite-holder {
    transform: translate(-70px, -70px);
    @include mq(phone,max){
      transform: translate(-70px, -70px) scale(0.5);
      
    }
  }

  .videocall-controls {
    // transform:translate(-50%,100px);
    // transform: translate(-50%, 150%);
    opacity: 0;
  }

  .joined-peers {
    transform: translate(-100%, -50%);
  }
  .call-duration-indicator {
    transform: translateX(50px);
  }

  .recording-indicator {
    transform: translateX(50px);
  }
  .poor-connection{
    transform: translateX(35px);
  }
}

