%btn-ctrl {
  border: 2px solid transparent;
  width: 55px;
  height: 55px;
  @include border-radius(50%);
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  transition: all .3s;
}

[class*=btn-ctrl] {


  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.small {
    height: 40px;
    width: 40px;

  }

  &:hover {
    .tooltip {
      display: block;

      &.down {
        bottom: -28px;
      }

      &.up {
        top: -28px;
      }

      &.right {
        right: -55px;
        bottom: 50%;
        transform: translate(0, 50%);
      }

      &.left{
        left: -55px;
        bottom: 50%;
        transform: translate(0, 50%);

      }

    }


  }
}

.btn-ctrl {
  &-default{
    @extend %btn-ctrl;
   
  }

  &-primary {
    @extend %btn-ctrl;
    background-color: $cornflowerBlue;

    &:hover {
      box-shadow: 0 2px 14px 0 $cornflowerBlue;
    }

    &.pressed {
      background-color: $darkblue;
      box-shadow: 0 2px 14px 0 $cornflowerBlue;
    }

  }

  &-secondary {
    @extend %btn-ctrl;
    background-color: $white;

    &:hover {
      box-shadow: 0 3px 18px 0 rgba(255, 255, 255, 0.5);
    }

    &.pressed {
      background-color: $ash;
      box-shadow: 0 3px 18px 0 rgba(255, 255, 255, 0.5), 0 7px 14px 0 rgba(0, 0, 0, 0.16);
    }
  }

  &-tertiary {
    @extend %btn-ctrl;
    border: solid 2px $blood-red;
    background-color: $blood-red;

    &:hover {
      svg {
        -webkit-filter: drop-shadow(0px 0px 2px $white);
        filter: drop-shadow(0px 0px 2px $white);
      }

    }

    &.pressed {

      svg {
        path {
          fill: $nobel;
        }
      }

      &:hover {
        svg {
          -webkit-filter: none;
          filter: none;
        }
      }
    }

  }

  &-quaternary {
    @extend %btn-ctrl;
    background-color: rgba($black, 0.7);

    &:hover {
      svg {
        -webkit-filter: drop-shadow(0px 0px 2px $white);
        filter: drop-shadow(0px 0px 2px $white);
      }

    }

    &.pressed {

      svg {
        path {
          fill: $nobel;
        }
      }

      &:hover {
        svg {
          -webkit-filter: none;
          filter: none;
        }
      }
    }

  }


}